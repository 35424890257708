import React from "react";
import { Link } from "gatsby";

import tsconfig from "../../tsconfig.json";

import { Code, CodeBlock } from "../docs/docs-code";
import { ContentSection, HeaderSection } from "../docs/sections";
import { DocPageMeta, Header } from "../docs";
import { Ol, Paragraph, TextContainer } from "../../lib/src";

export const Meta: DocPageMeta = {
	category: "Developing",
	title: "Getting started in a new project",
};

export default (): JSX.Element => {
	return (
		<>
			<HeaderSection
				title="Getting started in a new project"
				subTitle="These are the steps you need to go through to start consuming FancyLib or FancyLab in your
				frontend project"
			/>
			<ContentSection>
				<TextContainer article>
					<Header.H2>Using the GitHub NPM Registry</Header.H2>
					<Paragraph>
						This project is distributing NPM packages through the GitHub NPM registry. In order to
						install the packages you need to authenticate your local NPM client against the GitHub
						registry and set up the <Code>@siteimprove</Code> NPM scope to use the GitHub registry.
					</Paragraph>
					<Paragraph>
						Add the following lines to your user home directory <Code>~/.npmrc</Code>:
					</Paragraph>
					<CodeBlock language="ini">
						{`# Map @siteimprove npm scope to GitHub NPM Registry
@siteimprove:registry=https://npm.pkg.github.com`}
					</CodeBlock>
					<Header.H3>GitHub NPM Authentication</Header.H3>
					<Ol
						items={[
							<>
								Add the following lines to your <Code>~/.npmrc</Code>
								<CodeBlock language="ini">
									{`# Authenticate against GitHub NPM Registry
//npm.pkg.github.com/:_authToken=PASTE_AUTH_TOKEN_HERE`}
								</CodeBlock>
								Keep the file open, so you can paste in the token you will get in a moment.
							</>,
							<>
								Go to{" "}
								<a
									href="https://github.com/settings/tokens/new"
									target="_blank"
									rel="noopener noreferrer"
								>
									create a new GitHub personal access token.
								</a>
							</>,
							<>
								Add a note about what this token will be used for:{" "}
								<Code>GitHub NPM registry read-only</Code>.
							</>,
							<>
								Enable the <Code>read:packages</Code> scope.
							</>,
							<>
								Press <Code>Generate token</Code> button.
							</>,
							<>Copy the token value when it has been created</>,
							<>
								Replace <Code>PASTE_AUTH_TOKEN_HERE</Code> in <Code>~/.npmrc</Code> with your copied
								token.
							</>,
							<>
								Save <Code>~/.npmrc</Code>. You are now ready to install packages from GitHub.
							</>,
						]}
					/>
					<Header.H2>Install Fancy</Header.H2>
					<Paragraph>
						Run <Code>npm install --save @siteimprove/fancylib</Code> or{" "}
						<Code>npm install --save @siteimprove/fancylab</Code>
					</Paragraph>
					<Header.H2>Configure Webpack</Header.H2>
					<Paragraph>
						In order to consume Fancy from Webpack, you might need to configure your Webpack with
						more loaders than you already have. Your Webpack setup might already have the nessessary
						configurations, so you might want to just try things out and see if your existing setup
						already works. We do not recommend adding more configuration than you need.
					</Paragraph>
					<Header.H3>Loading JS</Header.H3>
					<Paragraph>
						Fancy is written in TypeScript, but distributes the compiled JavaScript along with its
						TypeScript definitions. This is to reduce the workload in the consuming projects and
						avoid a tight coupling between TypeScript compiler versions between Fancy and consuming
						projects.
					</Paragraph>
					<Paragraph>
						The distributed JavaScript code is as modern as it can be, which currently means we
						compile with a target version of <Code>{tsconfig.compilerOptions.target}</Code>. It is
						up to the individual consuming project to further compile the distributed JavaScript
						down to the level they need to hit their browser support target.
					</Paragraph>
					<Paragraph>
						Here is how you can set up a Webpack loader that loads FancyLib and FancyLab JavaScript
						and run it through Babel to enable you to compile the target version to your browser
						support target:
					</Paragraph>
					<CodeBlock language="shell">{`npm install --save-dev babel-loader`}</CodeBlock>
					<CodeBlock language="js">
						{`const webpackConfig = {
	module: {
		rules: [
			{
				test: /node_modules[\/\\]@siteimprove[\/\\]fancy.*\.js$/,
				exclude: /node_modules[\/\\]@siteimprove[\/\\]fancy.*[\/\\]node_modules/,
				loaders: ['babel-loader'],
			},
		]
	}
}`}
					</CodeBlock>
					<Paragraph>
						In the above example it is assumed that you use an external{" "}
						<a href="https://babeljs.io/docs/en/config-files#configuration-file-types">
							Babel config file
						</a>{" "}
						in order to avoid config repetition across multiple Babel loader instances
					</Paragraph>
					<Header.H3>Loading CSS</Header.H3>
					<Paragraph>
						Fancy uses CSS modules with Sass as its source format, but the distribution files are
						already compiled CSS files, which simplifies bundler setup in the consuming projects.
					</Paragraph>
					<Paragraph>
						This is a reduced CSS loading setup with CSS extraction to a static file:
					</Paragraph>
					<CodeBlock language="shell">
						{`npm install --save-dev mini-css-extract-plugin css-loader`}
					</CodeBlock>
					<CodeBlock language="js">
						{`const MiniCssExtractPlugin = require('mini-css-extract-plugin');
const webpackConfig = {
	plugins: [new MiniCssExtractPlugin(
		// Your configuration here
	)],
	module: {
		rules: [
			{
				test: /node_modules[\\\\/]@siteimprove[\\\\/]fancy.*\\.css$/,
				loaders: [MiniCssExtractPlugin.loader, "css-loader"],
			},
		]
	}
}`}
					</CodeBlock>
					<Paragraph>
						It is recommended that you extend the CSS loading pipeline with{" "}
						<a href="https://webpack.js.org/loaders/postcss-loader/">postcss-loader</a> and extend
						it with the{" "}
						<a href="https://github.com/csstools/postcss-preset-env">postcss-preset-env</a> plugin
						to get the CSS targeted at your browser range configured in{" "}
						<a href="https://github.com/browserslist/browserslist">.browserslistrc</a>.
					</Paragraph>
					<Header.H3>Loading File Assets</Header.H3>
					<Paragraph>
						Fancy will load asset types that are not CSS or JavaScript, which just need to be
						included via a normal URL and created as externals files as part of your bundler run.
						Webpack needs to be configured to be able to deal with these files. For this we use{" "}
						<Code>file-loader</Code>:
					</Paragraph>
					<CodeBlock language="shell">{`npm install --save-dev file-loader`}</CodeBlock>
					<CodeBlock language="js">
						{`const webpackConfig = {
	module: {
		rules: [
			{
				test: /node_modules\\/@siteimprove\\/fancy.*\\.(woff|woff2|svg|png|jpg|jpeg|gif)$/,
				loaders: ["file-loader"],
			},
		]
	}
}`}
					</CodeBlock>
					<Header.H2>Fancy Context</Header.H2>
					<Paragraph>
						Fancy provides a React context that must be wrapped at an outer level of where Fancy
						components are used. For now the context is used to identify the chosen language for
						translations. The context also inserts an element that applies the global styles from
						Fancy to all decendendant. The global styles are required to apply things like fonts to
						Fancy and non-Fancy components inside a Fancy application.
					</Paragraph>
					<CodeBlock language="tsx">
						{`import React from 'react';
import { FancyContext } from '@siteimprove/fancylab';
import "@siteimprove/fancylib/global.css"; // Global styles for general styling

export const App : React.FC = () => {
	return <FancyContext lang="en-US">{'...'}</FancyContext>;
}`}
					</CodeBlock>
					<Header.H2>Configure Your Build Pipeline</Header.H2>
					<Paragraph>
						Exactly as in <Link to="#npm-configuration">Configure your NPM client</Link>, your CI
						pipeline needs an authentication token in order to be able to download the{" "}
						<Code>fancylib</Code> and <Code>fancylab</Code> packages from the private GitHub package
						repository.
					</Paragraph>
					<Header.H3>Using the Gihub NPM Registry</Header.H3>
					<Paragraph>
						In order to map the <Code>@siteimprove</Code> npm scope to the GitHub Package
						Repository, add an <Code>.npmrc</Code> at the root of your node project and add the
						following lines:
					</Paragraph>
					<CodeBlock language="ini">
						{`# Map @siteimprove npm scope to GitHub NPM Registry
@siteimprove:registry=https://npm.pkg.github.com`}
					</CodeBlock>
					<Header.H3>Authenticate your CI client to GitHub packages</Header.H3>
					<Paragraph>
						In order to authenticate your CI client to GitHub Packages you need an auth token, which
						you create in the same way as described above for your personal account. To avoid
						coupling your CI access to any individual person, create the <Code>read:packages</Code>{" "}
						with the siteimprove GitHub account.
					</Paragraph>
					<Paragraph>
						You need to add the token to your CI environments secrets, for examples as{" "}
						<Code>GITHUB_PACKAGES_READ_TOKEN</Code>.
					</Paragraph>
					<Paragraph>
						From here you need to expose that secret in the environment that will end up running
						your <Code>npm install</Code> or <Code>npm ci</Code> command.
					</Paragraph>
					<Paragraph>
						Before the npm installation step you need to append the authentication token to your CI
						working copy of <Code>.npmrc</Code>:
					</Paragraph>
					<CodeBlock language="shell">
						{`echo "//npm.pkg.github.com/:_authToken=$GITHUB_PACKAGES_READ_TOKEN" >> .npmrc`}
					</CodeBlock>
				</TextContainer>
			</ContentSection>
		</>
	);
};
